import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Info from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import MUIDataTable from 'mui-datatables';
import ButtonMU from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';

import TagList from './TagList';

// const displayCustomValue =  (value, tableMeta, updateValue) => {
//   return {< class=>}
// }

const styles = {
    nowrap: {
        whiteSpace: 'nowrap'
    },
    button: {
        display: 'flex',
    },
    filler: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 400
    },
    card: {
        width: 'auto',
        maxWidth: 800,
        margin: 5,
        display: 'block',
        color: 'rgba(0, 0, 0, 0.74)',
        fontWeight: 700,
        fontSize: 13,
    },
    listContainer: {
        paddingBottom: 50,
        textAlign: "-webkit-center"
    },
    grid: {
        textAlign: 'center',
        minHeight: 100
    },
    titre: {
        fontSize: 16,
        fontWeight: 700
    },
    selectedClusters: {
        textAlign: "center",
        marginTop: 50
    },
    editeur: {
        fontWeight: '100'
    },
    words: {
        display: 'flex',
        justifyContent: 'space-between',
        // maxWidth: '1000px',
        margin: 'auto',
        marginTop: 50,
        marginLeft: 100,
        flexDirection: 'column'
    },
    wordsStatut: {
        display: 'flex',
    }
};
const decode = (string) => {
    let finalString = '';
    try { finalString = decodeURIComponent(escape(string)) }
    catch(error) { finalString = string }
    return finalString;
}

function SelectedSubjectSummary(props) {
    const { classes, sujetsList, selectedTopic, startAnalysis, entities, lemmes } = props;

    let articlesList = (c, index, classes, details=false) => {
      const scores = c.details ? c.details.scores : 0;
      if (details == true) {
        const columns = [
            { name: 'Editeur' },
            { name: 'Titre', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  return decode(value)
                }
              }
            },
            { name: 'Mots' },
            { name: 'Images' },
            { name: 'Videos' },
            { name: 'Typologie' },
            { name: 'Auteur' },
            { name: 'InterTitres' },
            { name: 'Attractivité du titre' },
            { name: 'Declencheurs dans le titre' },
            { name: 'URL', options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (<a href={`/dashboard/article/${tableMeta.rowData[10]}`} target="_blank"><ButtonMU><LinkIcon /></ButtonMU></a>)
                }
            } },
          ];
          const options = {
            filter: false,
            selectableRows: 'none',
            responsive: "stacked",
            rowsPerPage: 50,
            rowsPerPageOptions: [10,50, 100],
            downloadOptions: { filename: 'articles.csv', separator: ';' },
            //onRowClick: (rowData) => window.open(`${window.location.origin}/dashboard/article/${rowData[10]}`),
          };
          return <MUIDataTable className="datatable"
            data={c}
            columns={columns}
            options={options}
          />
      } else {
          return (
              <Card key={index} className={classes.card}>
                  <Grid className={classes.grid} container justify="space-between" alignItems="center" spacing={8}>
                      <Grid className={classes.editeur} xs={2}>
                          {c.editeur}
                      </Grid>
                      <Grid className={classes.titre} item xs={8}>
                          {c.titre}
                      </Grid>
                      <Grid item xs={2}>
                          <a href={`/dashboard/article/${c._id}`} target="_blank">
                              <Info />
                          </a>
                      </Grid>
                  </Grid>
                  <Grid className={classes.grid} container justify="space-between" alignItems="center" spacing={8}>
                    {/*    <div className={classes.container}>
                      <div className={`${colorsLabels[coverage]} color-fill`}>
                        {coverageIcons[coverage]}
                      </div>
                      <div className={classes.label}>
                        {coverageLabel[coverage]}
                      </div>
                    </div>*/}
                      <Grid className={classes.editeur} xs={2}>
                        Mots {scores.words}
                      </Grid>
                      <Grid className={classes.editeur} xs={2}>
                        Videos {scores.videos}
                      </Grid>
                      <Grid className={classes.editeur} xs={2}>
                        Images {scores.images}
                      </Grid>
                      <Grid className={classes.editeur} xs={2}>
                        Declencheurs Titre {scores.declencheurs}
                      </Grid>
                      <Grid className={classes.editeur} xs={2}>
                        Images {scores.images}
                      </Grid>
                  </Grid>
              </Card>
          )
      }
    }

    if (sujetsList && sujetsList.length > 0) {
        return (
            <div>
                <Button variant="contained" color="primary" className={classes.button} onClick={props.mergeClusters}><ArrowBackIcon /> Revenir vers les sujets</Button>

                <div className={classes.words}>
                    <div className={classes.wordsStatut}>
                        <h3>Statut : </h3>
                        <TagList array={[selectedTopic.status]} />
                    </div>
                    <div className={classes.wordsStatut}>
                        <h3>Top 10 Entités par fréquence d'apparition : </h3>
                        <TagList array={entities.map(e => e.entite + ' (' + e.frequence + ')')} />
                    </div>
                    <div className={classes.wordsStatut}>
                        <h3>Top 10 Lemmes par fréquence d'apparition : </h3>
                        <TagList array={lemmes.map(l => l.lemme + ' (' + l.frequence + ')')} />
                    </div>
                    <div>
                        {
                            selectedTopic.status != "saved" || selectedTopic.status != "completed" &&
                            <Button size="small" variant="contained" color="secondary" className={classes.button} onClick={() => startAnalysis(selectedTopic._id)}>
                                Lancer l'analyse
                            </Button>
                        }
                    </div>
                </div>

                <div className={classes.selectedClusters}>
                    <h2>{sujetsList.length} Articles Sélectionnés</h2>
                    <div className={classes.listContainer} >
                    {
                        sujetsList.length > 0 && Array.isArray(sujetsList[0]) ?
                            articlesList(sujetsList, 0, classes, true) :
                        selectedTopic.articles.map((c, index) => {
                            return articlesList(c, index, classes)
                        })
                    }
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.filler} >
                <CircularProgress className={classes.progress} />
            </div>
        );
    }
}

SelectedSubjectSummary.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectedSubjectSummary);
