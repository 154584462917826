import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Info from '@material-ui/icons/Info';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SelectedSubjectSummary from './sub_components/SelectedSubjectSummary';

const styles = theme => ({
    subtitle: {
        color: 'grey',
        textAlign: 'center'
    },
    listContainer: {
        paddingBottom: 150,
    },
    filler: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 400
    },
    grid: {
        textAlign: 'center',
        minHeight: 100
    },
    label: {
        fontWeight: 700,
        fontSize: 11,
        lineHeight: '11px',
        color: 'rgba(0, 0, 0, 0.34)',
        textAlign: 'center'
    },
    titre: {
        fontSize: 16,
        fontWeight: 700
    },
    btnsField: {
        display: "flex",
        width: 500,
        margin: "auto",
    },
    card: {
        width: '-webkit-fill-available',
        maxWidth: 950,
        margin: '0 auto',
        marginTop: 10,
        marginBottom: 10
    },
    info: {
        marginTop: 5,
        fontSize: 14,
        fontWeight: 700
    },
    button: {
        display: 'flex',
        margin: 'auto',
        marginRight: 10
    },
    cardContent: {
        display: "flex",
        justifyContent: "space-between",
        padding: 10
    },
    cardContentInfo: {
        display: 'flex',
        alignItems: 'center',
        width: "90%"
    },
    cardContentInfoData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: "20%"
    },
    cardContentInfoDataClassement: {
        textAlign: "center",
        width: "30%"
    },
    cardContentBtn: {
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableLink: {
        cursor: 'pointer',
        paddingTop: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    time: {
        width: 40,
        margin: 'auto',
        fontSize: 14,
        fontWeight: 700
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    },
});

class SelectionArticles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            render: true,
            subject: [],
            articles: [],
            merge: false,
            getSujets: [],
            selectedTopic: {},
            displaySubjects: false,
            intervalId: null,
            lemmes: [],
            entities: []
        }
    };

    getData = () => {
        this.setState({ render: false, displaySubject: false });
        const query = this.props.query;

        axios.get(`${config.backEndServer}/api/article/analyse_articles`, { ...query, sitemap: true }
            , { withCredentials: true, timeout: 60000 })
            .then((res) => {
                this.setState({ render: true, subject: res.data.analyses });
            })
            .catch(err => {
                this.setState({ render: 'timeout' });
            });
        axios.post(`${config.backEndServer}/api/article/analyse_articles`, { ...query, sitemap: true }
            , { withCredentials: true, timeout: 60000 })
            .then((res) => {
                this.setState({ render: true, articles: res.data });
            })
            .catch(err => {
                this.setState({ render: 'timeout' });
            });
    };

    componentDidMount = () => {
        this.getData();
    }

    componentWillUnmount = () => {
        this.stopRefreshing();
    }

    selectedArticles = (event, article) => {
        let selectedArticles = this.state.articles;
        const index = selectedArticles.indexOf(article);

        selectedArticles[index][0] = !selectedArticles[index][0];

        this.setState({
            articles: selectedArticles,
        });
    }

    ArticlesList = (c, index, classes, checked) => {
        return (
            <Card key={index} className={classes.card}>
                <Grid className={classes.grid} container justify="space-between" alignItems="center" spacing={8}>
                    <Grid item className="color-fill" xs={1}>
                        <Checkbox
                            checked={checked}
                            onChange={(event) => { this.selectedArticles(event, c) }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item className="color-fill" xs={1}>
                        {c[1]}
                    </Grid>
                    <Grid className={classes.titre} item xs={8}>
                        {c[6]}
                    </Grid>
                    <Grid item xs={2}>
                        <a href={`/dashboard/article/${c[18]}`} target="_blank">
                            <Info />
                        </a>
                    </Grid>
                </Grid>
            </Card>
        )
    }

    startAnalysis = (id) => {
      axios.post(`${config.backEndServer}/api/article/analyse_articles/startAnalysis`, {
          id: id,
      }, { withCredentials: true })
          .then((res) => {
              if (res.data.msg == "ok") {
                  toast.success('L analyse est lancée', {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
                  this.setState({ sujetPopUp: !this.state.sujetPopUp });
              }
          });
    }

    saveData = (name, articles) => {
        let ids = [];

        articles = articles.filter(i => i[0] == true);
        articles.map(i => ids.push(i[18]));

        axios.post(`${config.backEndServer}/api/article/analyse_articles/save`, {
            filterName: name,
            articles: ids,
            fromDate: this.props.query.fromDate,
            toDate: this.props.query.toDate
        }, { withCredentials: true })
            .then((res) => {
                if (res.data.msg == "ok") {
                    toast.success('La liste a été enregistrée', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({ sujetPopUp: !this.state.sujetPopUp });
                }
            });
    }

    moreDetails = (liste) => {
      axios.post(`${config.backEndServer}/api/article/analyse_articles/details`, {
          id: liste._id,
        }, { withCredentials: true })
        .then(res => {
          const details = res.data
          if (details && details.arrayDetails && details.arrayDetails.length > 0) {
            this.setState({ getSujets: details.arrayDetails, lemmes: details.lemmes, entities: details.entities, selectedTopic: liste, merge: true });
            this.stopRefreshing();
          } else {
            this.setState({ getSujets: liste.articles, selectedTopic: liste, merge: true});
            }
        })
    }


    startRefreshing = (liste) => {
        this.setState({ merge: true }, () => {
            this.moreDetails(liste);
            const intervalId = setInterval(() => {
                this.moreDetails(liste);
            }, 10000);
            this.setState({ intervalId });
        });
    };

    stopRefreshing = () => {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
            this.setState({ intervalId: null });
        }
    }

    deleteDetails = (liste) => {
        axios.post(`${config.backEndServer}/api/article/analyse_articles/delete`, {
            id: liste._id,
        }, { withCredentials: true })
            .then((res) => {
                if (res.data == "ok") {
                    toast.success('Le sujet a été supprimé', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    window.location.reload();
                }
            });
    }

    mergeClusters = () => {
        this.setState({ merge: !this.state.merge });
        this.stopRefreshing();
    }

    resetHome = () => {
      this.setState({ displaySubjects: true})
    }

    SujetsList = (c, index, classes) => {
        return (
            <div key={index} className={classes.content}>
                <div className={classes.time}>{c.date_creation && moment(c.date_creation).format("DD/MM HH:mm")}</div>
                <div className={classes.time}>{c.fromDate && moment(c.fromDate).format("DD/MM")} {c.toDate && moment(c.toDate).format("DD/MM")}</div>
                <Card className={classes.card}>
                    <div className={classes.cardContent}>
                        <div className={classes.cardContentInfo}>
                            <div className={classes.cardContentInfoData}>
                            <Fragment>
                                <div className={classes.info}>
                                    {c.articles.length}
                                </div>
                                <div className={classes.label}>
                                    articles
                                </div>
                            </Fragment>
                            </div>
                            <div>
                                <div className={classes.titre}>
                                    {c.name}
                                </div>
                            </div>
                        </div>
                        <div className={classes.cardContentBtn}>
                            <Button variant="contained" color="primary" size="small" className={classes.button} onClick={() => this.startRefreshing(c)}>Détails</Button>
                            <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={() => this.deleteDetails(c)}>Supprimer</Button>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }

    render = () => {
      const { classes, query } = this.props;
      const { subject, merge, getSujets, selectedTopic, displaySubjects, entities, lemmes } = this.state;

        if (this.state.render == true) {
          if (query.urlEntities && query.urlEntities.length > 2 && !displaySubjects) {
                return (<div>
                    <Button variant="contained" color="primary" className={classes.button} onClick={() => this.resetHome()}><ArrowBackIcon /> Revenir vers les sujets</Button>
                    <h1>Tous les articles ({this.state.articles.length} résultats)</h1>
                    <div className={classes.listContainer}>
                        {this.state.articles.map((c, index) => {
                            return this.ArticlesList(c, index, classes, c[0])
                        })}
                        <div className={classes.btnsField}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={() => this.saveData(query.urlEntities, this.state.articles)}>
                                Sauvegarder la liste
                            </Button>
                        </div>
                    </div>
                </div>)
            } else if (subject && subject.length > 0) {
                if (!merge) {
                    return (
                        <div className="article-container">
                            <h1>Tous les sujets ({subject.length} résultats)</h1>
                            <div className={classes.listContainer} >
                                {subject.map((c, index) => {
                                    return this.SujetsList(c, index, classes)
                                })}
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <SelectedSubjectSummary
                            sujetsList={getSujets}
                            query={this.props.query}
                            mergeClusters={this.mergeClusters}
                            selectedTopic={selectedTopic}
                            startAnalysis={this.startAnalysis}
                            entities={entities}
                            lemmes={lemmes}
                        />)
                }
            } else {
                return (<div>
                    <h3>Pas de données</h3>
                </div>)
            }
        } else {
            return (
                <div className={classes.filler} >
                    <CircularProgress className={classes.progress} />
                </div>
            );
        }
    };
}

SelectionArticles.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectionArticles);
